import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SampleForm from "../components/form/form"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>CONTACT US</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content pb-0">
        <section className="about-section" id="about-section-1">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="container">
                  <div className="bg-purple-content" />
                  <div className="content">
                    <h1 className="text-white mb-5">
                      <strong>OUR LOCATIONS</strong>
                    </h1>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="text-white">
                          <ul className="list-style-none">
                            <li>
                              <strong>US Address:</strong>
                            </li>
                            <li>204 Bowen St .</li>
                            <li>Longmont, CO</li>
                            <li>90501, USA</li>
                          </ul>
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="text-white">
                          <ul className="list-style-none">
                            <li>
                              <strong>UK Address:</strong>
                            </li>
                            <li>35 Palgrave Road</li>
                            <li>Bedford, Bedfordshire</li>
                            <li>MK42 9DH</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="wholesale" />
          </div>

          <div id="contact-bg" className="col-md-7" />
        </section>

        <div id="start-your-business" />
        <section className="about-section" id="about-section-3">
          <div className="container">
            <div className="row">
              <div className="col-md-7 ml-auto">
                <div className="container">
                  <div className="bg-purple-content" />
                  <div className="content">
                    <div class="container">
                      <div className="mt-3 mb-0 row">
                        <h6 className="text-white">
                          <strong>For inquiries please contact:</strong>
                        </h6>
                      </div>
                      <a class="mb-1 row" href="#">
                        <i class="fa fa-map-marker col-md-1 pl-0 text-darkgold" />
                        &nbsp;
                        <br />
                        <p class="col text-left text-white">
                          6525 Gunpark Dr #370-233 Boulder Co 80301
                          <br />
                        </p>
                      </a>
                      <a class="mb-1 row" href="#">
                        <i class="fa fa-phone col-md-1 pl-1 text-darkgold" />
                        &nbsp;
                        <br />
                        <p class="col text-left text-white">
                          303.223.1665
                          <br />
                        </p>
                      </a>
                      <a class="mb-1 row" href="#">
                        <i class="fa fa-phone-square col-md-1 pl-1 text-darkgold" />
                        &nbsp;
                        <br />
                        <p class="col text-left text-white">
                          +1 (603) 568- 2860 (Free messaging and calling)
                          <br />
                        </p>
                      </a>
                      <a class="mb-1 row" href="#">
                        <i class="fa fa-envelope col-md-1 pl-1 text-darkgold" />
                        &nbsp;
                        <br />
                        <p class="col text-left text-white">
                          help@heavenly-candy.com
                          <br />
                        </p>
                      </a>
                      <div className="mt-3 mb-0 row">
                        <h6 className="text-white">
                          <strong>For sales please contact:</strong>
                        </h6>
                      </div>
                      <a class="mb-1 row" href="#">
                        <i class="fa fa-envelope col-md-1 pl-1 text-darkgold" />
                        &nbsp;
                        <br />
                        <p class="col text-left text-white">
                          sales@heavenly-candy.com
                          <br />
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="about-bg" className="col-md-7" />
        </section>
        <section className="about-section" id="about-section-4">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="container">
                  <div className="content text-center">
                    <h1 className="text-white">
                      <strong>CONTACT US FOR WHOLESALE</strong>
                    </h1>
                    <p className="text-white mb-5">
                      If you are interested in buying our products at wholesale
                      pricing, please fill out the contact form below and a
                      member of our team will be in touch shortly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-10 mx-auto text-center">
                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5bcd42f86b63453b251972bc"
                  form_classname="form-newsletter"
                >
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          required=""
                        />
                        <label htmlFor="firstName">First Name</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          required=""
                        />
                        <label htmlFor="lastName">Last Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      id="homeEmail"
                      required=""
                    />
                    <label htmlFor="homeEmail">Email address</label>
                  </div>
                  <div className="form-group">
                    <input className="form-control" id="phone" type="tel" />
                    <label htmlFor="phone">Phone (optional)</label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control h-auto"
                      id="message"
                      required=""
                      rows="4"
                    />
                    <label htmlFor="message">Message</label>
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group text-center mt-5 btn-container">
                    <button
                      className="button-gold font-weight-bold"
                      type="submit"
                    >
                      CONTACT US
                    </button>
                  </div>
                </SampleForm>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage
